@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	@apply border-border;
}

body {
	padding: 16px;
}
